import { graphql, StaticQuery } from "gatsby"
import React, { useEffect, useState } from "react"
import { Col, Container, Modal, Row } from "react-bootstrap"
import Buttons from "../../../components/common/button"
import CTA from "../../../components/common/CTA"
import Frame from "../../../components/common/frame"
import LatestBlogCards from "../../../components/common/latest-blog-cards"
import Navigation from "../../../components/navigation"
import Slide from "../../../components/utility/slide_content"
import "../../../styles/pages/new-home.scss"
import "../../../styles/resources.scss"
import Close from "./../../../assets/images/close-white.png"

const ResourcePage = props => {
  const [innerWidth, setInnerWidth] = useState(0)

  useEffect(() => {
    setInnerWidth(document.body.clientWidth)
  })

  const [show, setShowVideo] = useState(false)
  const [slideAvailable, setSlideAvailable] = useState()

  const handleClose = () => {
    setShowVideo(false)
  }
  const handleShow = val => {
    setSlideAvailable(val)
    setShowVideo(true)
  }

  return (
    <div className="New-Home New-Resources">
      <StaticQuery
        query={graphql`
          query resourcePage {
            SuperOps {
              webinars {
                pageName
                slug
                heroHeading
                heroSubheading
                speakerImage {
                  url
                }
                seo {
                  title
                  description
                }
                relatedVideo {
                  slug
                  heroHeading
                  heroSubheading
                  speakerBio
                  speakerImage {
                    url
                  }
                  outcomesBackgroundColor
                  downloadFile {
                    url
                  }
                  videoLink
                  footerText
                }
              }
              pages(where: { title: "Resource" }) {
                navigationBlock {
                  name
                  slug
                  isDropdown
                }
                seo {
                  title
                  description
                  keywords
                  image {
                    url
                  }
                }
              }
              navigationContents {
                navigation
                section
                parentTitle
                parentDescription
                childTitle
                childSlug
                childDescription
                childIcon {
                  url(
                    transformation: { document: { output: { format: webp } } }
                  )
                }
                parentIllustration {
                  url
                }
                boxBg
              }
              posts(first: 3, orderBy: date_DESC, where: { isBlog: Yes }) {
                title
                coverImage {
                  id
                  url(
                    transformation: {
                      document: { output: { format: webp } }
                      image: { resize: { width: 770 } }
                    }
                  )
                }
                tags
                slug
              }

              ctaBoxes(where: { where: "Signup-box" }) {
                type
                theme
                heading {
                  html
                }
                description {
                  html
                }
                primaryButtonText
                primaryButtonLink
                secondaryButtonText
                secondaryButtonLink
              }
            }
          }
        `}
        render={data => (
          <>
            {data.SuperOps.webinars.map((webinar, idx) => {
              return webinar.pageName === "Resourcepage" ? (
                <div>
                  <Frame
                    title={webinar.seo.title}
                    description={webinar.seo.description}
                    keywords={webinar.seo.keywords}
                    ogTitle={webinar.seo.title}
                    ogDescription={webinar.seo.description}
                    ogImage={webinar.seo.image}
                    ogType="website"
                  >
                    <header>
                      <Navigation
                        linkdata={data.SuperOps.pages.navigationBlock}
                        page="Home"
                      />
                    </header>
                    <main>
                      <section className="hero">
                        <Slide style={{ "--sal-delay": "0.2s" }}>
                          <Container>
                            <Row>
                              <Col lg={6} className="left">
                                <div className="text">
                                  <div className="title">
                                    <h1 className="fw-bolder mb30">
                                      {webinar.heroHeading}
                                    </h1>
                                  </div>
                                  <div className="subtitle p18">
                                    <p>{webinar.heroSubheading}</p>
                                  </div>
                                </div>
                                <div className="buttons d-flex">
                                  <Buttons
                                    theme="primary"
                                    link={"/signup"}
                                    text="GET STARTED FOR FREE"
                                    arrow
                                  />
                                  <Buttons
                                    theme="secondary"
                                    link={"/demo"}
                                    text="BOOK A DEMO"
                                    arrow
                                  />
                                </div>
                              </Col>
                              <Col lg={6} className="right">
                                <img
                                  src={webinar.speakerImage.url}
                                  alt="Hero Image"
                                  className="hero-image"
                                />
                              </Col>
                            </Row>
                          </Container>
                        </Slide>
                      </section>

                      <section className="usp-section sops-w-95 resources">
                        <Container>
                          {webinar.relatedVideo.map((item, idx) => {
                            return (
                              <Slide style={{ "--sal-delay": "0.2s" }}>
                                <Row
                                  className="book-wrapper"
                                  style={{
                                    backgroundColor: `${item.outcomesBackgroundColor[0]}`,
                                  }}
                                >
                                  <Col lg={7}>
                                    <div className="left-wrapper">
                                      <div className="p14 d-dsk">
                                        <p className="active">
                                          {item.heroSubheading}
                                        </p>
                                      </div>
                                      <div className="title d-dsk">
                                        <h3>{item.heroHeading}</h3>
                                      </div>
                                      <div className="glass-box">
                                        <div className="description p16 mb-4">
                                          <p>{item.speakerBio}</p>
                                        </div>
                                        {item.videoLink ? (
                                          <Buttons
                                            theme="primary"
                                            onClick={() =>
                                              handleShow(item.videoLink)
                                            }
                                            text={item.footerText}
                                            arrow
                                          />
                                        ) : item?.downloadFile?.url ? (
                                          <Buttons
                                            theme="primary"
                                            link={`${item.downloadFile.url}`}
                                            text={item.footerText}
                                            arrow
                                            newTab
                                            download
                                          />
                                        ) : (
                                          <Buttons
                                            theme="primary"
                                            link={`/${item.slug}`}
                                            text={item.footerText}
                                            arrow
                                          />
                                        )}
                                      </div>
                                    </div>
                                  </Col>
                                  <Col lg={5}>
                                    <div className="p14 d-mob">
                                      <p className="active">
                                        {item.heroSubheading}
                                      </p>
                                    </div>
                                    <div className="title d-mob">
                                      <h3>{item.heroHeading}</h3>
                                    </div>
                                    <div className="right-wrapper">
                                      <img
                                        className="book-img"
                                        src={item.speakerImage.url}
                                      />
                                    </div>
                                  </Col>
                                </Row>
                              </Slide>
                            )
                          })}
                        </Container>
                        {slideAvailable ? (
                          <Modal
                            className="yt"
                            show={show}
                            onHide={() => setShowVideo(false)}
                            centered
                            dialogClassName="modal-80w"
                            aria-labelledby="example-custom-modal-styling-title"
                          >
                            <img
                              src={Close}
                              alt="close"
                              className="close-icn"
                              onClick={handleClose}
                              decoding="async"
                            />
                            <Container className="yt-vdo">
                              <iframe
                                className="video"
                                src={slideAvailable}
                                frameborder="0"
                                allowfullscreen="allowfullscreen"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                              ></iframe>
                            </Container>
                          </Modal>
                        ) : null}
                      </section>

                      <section className="dark-box">
                        <Slide style={{ "--sal-delay": "0.2s" }}>
                          <Container>
                            <CTA
                              maxBoxWidth="1100px"
                              data={data.SuperOps.ctaBoxes}
                              maxDescriptionWidth="440px"
                            />
                          </Container>
                        </Slide>
                      </section>

                      <section className="blog-cards sops-blog-wrap">
                        <LatestBlogCards
                          heading="Stay in the know!"
                          ctaText="explore library"
                          ctaLink="blog"
                          items={data.SuperOps.posts}
                        />
                      </section>
                    </main>
                  </Frame>
                </div>
              ) : null
            })}
          </>
        )}
      />
    </div>
  )
}

export default ResourcePage
